export const prefixer = <Prefix extends string>(prefix: Prefix) => <ActionType extends string>(actionType: ActionType): `${Prefix}_${ActionType}` => `${prefix}_${actionType}`
export interface ApiConstants<ApiName extends string> {
  REQUESTED: `${ApiName}_REQUESTED`,
  SUCCESS: `${ApiName}_SUCCESS`,
  FAILURE: `${ApiName}_FAILURE`,
}
export const createApiConsts = <ApiName extends string>(apiName: ApiName): ApiConstants<ApiName> => ({
  REQUESTED: `${apiName}_REQUESTED`,
  SUCCESS: `${apiName}_SUCCESS`,
  FAILURE: `${apiName}_FAILURE`,
})
export type ApiNameFromConst<T> = T extends ApiConstants<infer ApiName> ? ApiName : never
